const MapIcon = (url: string): string => {
    if (url.toLowerCase().includes("facebook")) {
        return "ti-facebook";
    } else if (url.toLowerCase().includes("twitter")) {
        return "ti-twitter-alt";
    } else if (url.toLowerCase().includes("linkedin")) {
        return "ti-linkedin";
    } else if (url.toLowerCase().includes("pinterest")) {
        return "ti-pinterest";
    }
    return "";
};

export default MapIcon;