import React from "react";
import logo from "../../assets/images/cta-logo.png"
import "./CtaSection.css"

const CtaSection: React.FC = () => {
    return (
        <section className="cta-section">
            <div className="container" id="cta-section-container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="cta-content">
                            <h2>Služby pre  firmy</h2>
                            <a href="/sluzby/zalozenie-a-zrusenie-spolocnosti" className="theme-btn">Založenie a zrušenie spoločnosti</a>
                            <a href="/sluzby/zmeny-v-obchodnych-registroch" className="theme-btn-s3">Zmeny v obchodných registroch</a>
                            <a href="/sluzby/vymahanie-pohladavok" className="theme-btn">Vymáhanie pohľadávok</a>
                            <a href="/sluzby/danove-kontroly" className="theme-btn-s3">Daňové kontroly</a>
                            <a href="/sluzby/register-partnerov-verejneho-sektora" className="theme-btn">Register partnerov verejného sektora</a>
                            <a href="/sluzby/register-hospodarskych-subjektov" className="theme-btn-s3">Register hospodárskych subjektov</a>
                            <a href="/sluzby/gdpr" className="theme-btn">GDPR</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CtaSection;