import BlogItem from "./BlogItem";
import "./BlogSection.css";
import { useCrud } from "../../hooks/CrudHook";
import DbBlog from "../../utils/types/DbBlog";
import MapBlogFromDbToUi from "../../utils/mappers/BlogMapper";

const BlogSection: React.FC = () => {
    const { data, loading } = useCrud<DbBlog>({ endpoint: "articles" });
    let dbBlogs = MapBlogFromDbToUi(data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()));
    dbBlogs = dbBlogs.filter(b => (b.blog.category == 'blog' && b.meta.publish == true));
    if (dbBlogs.length > 0) {
        localStorage.removeItem("blogs");
        localStorage.setItem("blogs", JSON.stringify(dbBlogs));
    }
    if (loading) {
        return (
            <div>Loading ...</div>
        );
    }
    else {
        return (
            <section className="blog-section section-padding">
                <div className="row">
                    <div className="container">
                    <div className="col col-lg title-container">
                        <h2 className="section-title"><a href="/aktuality">Aktuality</a></h2>
                    </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col col-md-12">
                            <div className="blog-grids clearfix">
                                {dbBlogs.slice(0,3).map((item, index) => (
                                    <BlogItem
                                    title={item.blog.title}
                                    image={item.blog.images![0].url}
                                    date={item.meta.date}
                                    text={item.blog.description.slice(0, 150)}
                                    url={`/aktuality/${item.id}/${item.blog.title.toLocaleLowerCase().replaceAll(" ", "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`}
                                    key={index}/>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default BlogSection;