import React from "react";
import PageTitle from "../components/PageTitle";
import ContactSection from "../components/ContactSection/ContactSection";
import ContactMapSection from "../components/ContactSection/ContactMapSection";
import "./ContactPage.css";

const ContactPage: React.FC = () => {
    return (
        <div>
            <PageTitle pageName="Kontakt" desc="" id="kontakt"/>
            <ContactSection classString="contact-section-s3 contact-pg-section" />
        </div>
    );
}

export default ContactPage;