import React, { useEffect, useState } from "react";
import PageTitle from "../components/PageTitle";
import SingleContentSection from "../components/Content/SingleContentSection";
import "./PartnerRegistryPage.css";
import { BlogEntryItem } from "../utils/types/BlogEntryItem";

const PartnerRegistryPage: React.FC = () => {
    const [currentItemContent, setCurrentItemContent] = useState<string>("");
    const [currentItemTitle, setCurrentItemTitle] = useState<string>("");
    useEffect(() => {
        const data = localStorage.getItem("main-articles");
        if (data) {
            const items = JSON.parse(data) as BlogEntryItem[];
            const item = items.filter(i => (i.blog.category === "mainContent" && i.meta.url == "register-partnerov-verejneho-sektora"))[0];
            setCurrentItemContent(item.blog.content);
            setCurrentItemTitle(item.blog.title);
        }
    })

    return (
        <div>
            <PageTitle pageName="" desc="" id="register-partnerov-verejneho-sektora"/>
            <SingleContentSection title={currentItemTitle} text={currentItemContent} itemUrl="register-partnerov-verejneho-sektora"/>
        </div>
    )
}

export default PartnerRegistryPage;