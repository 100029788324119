import React from "react";

interface BlogButtonProps {
    nextId: number;
    prevId: number;
}

const BlogButtons: React.FC<BlogButtonProps> = ({nextId, prevId}) => {
    return (
        <div className="more-posts">
            <div className="previous-post">
                <a href={`/aktuality/${prevId}`}>
                    <span className="post-control-link">Predchádzajúci článok</span>
                </a>
            </div>
            <div className="next-post">
                <a href={`/aktuality/${nextId}`}>
                    <span className="post-control-link">Nasledujúci článok</span>
                </a>
            </div>
        </div>
    );
}

export default BlogButtons;