import React from "react";
import { Link } from "../../../utils/types/Link";

interface SubMenuProps {
    links: Link[]
}

const SubMenu: React.FC<SubMenuProps> = ({ links }) => {
    const itemLinks = links.slice(1);

    return (
        <li className="menu-item-has-children">
            <a href={links[0].url}>{links[0].linkName}</a>
            <ul className="sub-menu">
                {itemLinks.map((item, index) => (
                        <li key={index}>
                            <a href={item.url}>{item.linkName}</a>
                        </li>
                    ))}
            </ul>
        </li>
    );
};

export default SubMenu;