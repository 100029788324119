import FeatureSection from '../components/FeatureSection/FeatureSection';
import CtaSection from '../components/CtaSection/CtaSection';
import HeroSlider from '../components/HeroSlider/HeroSlider';

import BlogSection from '../components/BlogSection/BlogSection';
import { useCrud } from '../hooks/CrudHook';
import DbBlog from '../utils/types/DbBlog';
import MapBlogFromDbToUi from '../utils/mappers/BlogMapper';

const HomePage: React.FC = () => {
    const { data, loading } = useCrud<DbBlog>({ endpoint: "articles" });
    let dbBlogs = MapBlogFromDbToUi(data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()));
    const mainArticles = dbBlogs.filter(b => (b.blog.category == 'mainContent'));
    localStorage.removeItem("main-articles");
    localStorage.setItem("main-articles", JSON.stringify(mainArticles));
    if (loading) {
        return (
            <div>Loading ...</div>
        );
    }
    else {
        return (
            <div className="page-wrapper">
                <HeroSlider />
                <FeatureSection />
                <CtaSection />
                <BlogSection />
            </div>
        );
    }
}

export default HomePage;