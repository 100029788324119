import React from "react";

interface HeroSlideProps {
    background: string;
    dataText: string;
    titleText: string;
    slideText: string;
    buttonText: string;
    buttonLink: string;
}

const HeroSlide: React.FC<HeroSlideProps> = ({ background, dataText, titleText, slideText, buttonText, buttonLink }) => {
    return (
        <div className="slide-inner slide-bg-image" data-text={dataText}   style={{ backgroundImage: `url(${background})`, height: "400px" }}>
            <div className="container">
                <div data-swiper-paralax="300" className="slide-title">
                    <h1>{titleText}</h1>
                </div>
                <div data-swiper-paralax="400" className="slide-text" dangerouslySetInnerHTML={{ __html: slideText }}></div>
                <div className="clearfix"></div>
                <div data-swiper-paralax="500" className="slide-btns">
                    <a href={buttonLink} className="theme-btn"><h1></h1>{buttonText}</a>
                </div>
            </div>
        </div>
    )
}

export default HeroSlide;