import React from "react";
import ContactForm from "./ContactForm";

interface ContactSectionProps {
    classString: string;
}

const ContactSection: React.FC<ContactSectionProps> = ({ classString }) => {
    return (
        <section className={classString}>
            <div className="content-area clearfix">
                <div className="contact-info-col">
                    <div className="contact-info">
                        <ul>
                            <li>
                                <i className="fi flaticon-home-3"></i>
                                <h4>Kontakt</h4>
                                <p>Advokátska kancelária JUDr. Jaroslav Čibenka s.r.o.</p>
                                <p>Námestie slobody 1901/66</p>
                                <p>020 01 Púchov</p>
                                <p>IČO: 47254181</p>
                                <p>IČ DPH: SK2024064416</p>
                                <p>Zapísaná v OR SR. OS Trenčín odd. Sro vl.č. 30371/R</p>
                                <p>Bankové spojenie:<br></br>IBAN: SK39 0900 0000 0050 7610 4167<br></br>Slovenská sporiteľňa a.s.</p>
                                
                            </li>
                            <li>
                                <i className="fi flaticon-email"></i>
                                <h4>Email</h4>
                                <p><a href="mailto:office@cibenka.sk">office@cibenka.sk</a></p>
                            </li>
                            <li>
                                <i className="fi flaticon-support"></i>
                                <h4>Telefón</h4>
                                <p><a href="tel:+421940505051">+421 940 50 50 51</a></p>
                            </li>
                            <li>
                                <i className="fi flaticon-clock"></i>
                                <h4>Úradné hodiny</h4>
                                <p>Pon-Pia: 08:00 - 16:00</p>
                            </li>
                            
                        </ul>
                    </div>
                </div>
                <div className="contact-info-col">
                    <div className="section-title-s2">
                        <div className="icon">
                            <i className="fi flaticon-balance"></i>
                        </div>
                        <span className="contact-form-header">Kontaktný formulár</span>
                    </div>

                    <ContactForm />
                </div>
            </div>
        </section>
    );
}

export default ContactSection;