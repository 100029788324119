import React from "react";
import Navbar from "./Navbar/Navbar";
import TopBar from "./TopBar"
import { SocialMediaLink } from "../../utils/types/SocialMediaLink";

const Header:React.FC = () => {
    const links: SocialMediaLink[] = [
        { url: "https://www.facebook.com/advokatskakancelariaJUDr.Cibenka/", icon: "" }
    ];

    return (
        <header id ="header" className="site-header header-style-1">
            <TopBar phoneNumber={"+421 940 50 50 51"} address={"adresa"} email={"office@cibenka.sk"} links={links}/>
            <Navbar />
        </header>
    )
};

export default Header;