import React from "react"

interface Link {
    url: string,
    linkName: string
}

interface WidgetLinkProps {
    title: string,
    linksLeft: Link[],
    linksRight: Link[]
}

const WidgetLink: React.FC<WidgetLinkProps> = ({ title, linksLeft, linksRight }) => {
    return (
        <div className="col col-lg-3 col-md-3 col-sm-6">
            <div className="widget link-widget">
                <div className="widget-title">
                    <h3>{title}</h3>
                </div>
                <ul>
                    {linksLeft.map((item, index) => (
                        <li key={index}>
                            <a href={item.url}>{item.linkName}</a>
                        </li>
                    ))}
                </ul>
                {linksRight.length > 0 && (
                    <ul>
                        {linksRight.map((item, index) => (
                            <li key={index}>
                                <a href={item.url}>{item.linkName}</a>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}

export default WidgetLink;