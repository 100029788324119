import React from 'react';
import "./Popup.css";

interface PopupProps {
    message: string;
    onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ message, onClose }) => {
    return (
        <div className="popup">
            <div className="popup-inner">
                <h2>{message}</h2>
                <button className='theme-btn' onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default Popup;