import React from "react";
import { Image } from "../../utils/types/Image";
import "./FeatureItem.css";
import { Link } from "../../utils/types/Link";

interface FeatureItemProps {
    icon?: string;
    header: string;
    text: string;
    image?: Image;
    link?: Link;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ icon, header, text, image, link }) => {
    return (
        <div className="col col-lg-3 col-sm-6">
            <a href={link?.url}>
            <div className="feature-grid">
                {image && <img src={image.url} alt={image.alt}></img>}
                {icon && <i className={icon}></i>}
                <h2>{header}</h2>
                <p>{text}</p>
                {link && <a href={link.url} className="read-more">{link.linkName}</a>}
            </div>
            </a>
        </div>
    )
}

export default FeatureItem;