import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Popup from "../Popup";

const ContactForm: React.FC = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        note: ""
    });

    const [submissionStatus, setSubmissionStatus] = useState({
        loading: false,
        success: false,
        error: false
    });

    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const navigate = useNavigate();
    const endpoint = "https://visionwebmedia.cz/send_email";
    // const endpoint = "";

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setSubmissionStatus({ loading: true, success: false, error: false });

        const emailData = {
            Messages: [
                {
                    From: {
                        Email: "your-email@example.com",
                        Name: "Your Name"
                    },
                    To: [
                        {
                            Email: formData.email,
                            Name: formData.name
                        }
                    ],
                    Subject: formData.subject,
                    TextPart: `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nMessage: ${formData.note}`,
                    HTMLPart: `<h3>New message from ${formData.name}</h3><p><b>Email:</b> ${formData.email}</p><p><b>Phone:</b> ${formData.phone}</p><p><b>Message:</b> ${formData.note}</p>`
                }
            ]
        };

        try {
            const response = await axios.post(endpoint, emailData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 204) {
                setSubmissionStatus({ loading: false, success: true, error: false });
                setPopupMessage("Ďakujeme! Vaša správa bola odoslaná.");
            } else {
                setSubmissionStatus({ loading: false, success: false, error: true });
                setPopupMessage("Vyskytla sa chyba, prosím skúste poslať správu neskôr.");
            }
        } catch (error) {
            setSubmissionStatus({ loading: false, success: false, error: true });
            setPopupMessage("Vyskytla sa chyba, prosím skúste poslať správu neskôr.");
        }

        setShowPopup(true);
        navigate('/kontakt'); // Navigate back to the form page
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <div className="contact-form">
            {showPopup && <Popup message={popupMessage} onClose={closePopup} />}
            <form method="post" className="contact-validation-active" id="contact-form-main" onSubmit={handleSubmit}>
                <div>
                    <input type="text" className="form-control" name="name" id="name" placeholder="Meno*" value={formData.name} onChange={handleChange}></input>
                </div>
                <div>
                    <input type="email" className="form-control" name="email" id="email" placeholder="Email*" value={formData.email} onChange={handleChange}></input>
                </div>
                <div>
                    <input type="text" className="form-control" name="phone" id="phone" placeholder="Telefónne číslo*" value={formData.phone} onChange={handleChange}></input>
                </div>
                <div>
                    <select name="subject" className="form-control" value={formData.subject} onChange={handleChange}>
                        <option disabled value="">Služby</option>
                        <option>Právo Nehnuteľností</option>
                        <option>Obchodné právo</option>
                        <option>Daňové právo</option>
                        <option>Rodinné právo</option>
                        <option>Založenie a zrušenie spoločnosti</option>
                        <option>Zmeny v obchodných registroch</option>
                        <option>Vymáhanie pohľadávok</option>
                        <option>Daňové kontroly</option>
                    </select>
                </div>
                <div className="fullwidth">
                    <textarea className="form-control" name="note" id="note" placeholder="Krátky popis..." value={formData.note} onChange={handleChange}></textarea>
                </div>
                <div className="submit-area">
                    <button type="submit" className="theme-btn" disabled={submissionStatus.loading}>Odoslať</button>
                    {submissionStatus.loading && <div id="loader">
                        <i className="ti-reload"></i>
                    </div>}
                </div>
            </form>
        </div>
    );
}

export default ContactForm;
