import React from "react";

interface BlogItemProps {
    title: string;
    image: string;
    date: string;
    url: string;
    text: string;
}

const BlogItem: React.FC<BlogItemProps> = ({ title, image, date, url, text}) => {
    if (text.length === 150) {
        text = text + "..."
    }
    return (
        <div className="grid">
            <div className="entry-media">
                <img src={image} alt=""></img>
            </div>
            <div className="entry-details">
                <h5>{date}</h5>
                <h3><a href={url}>{title}</a></h3>
                <p>{text}</p>
                <a href={url} className="theme-btn">Čítajte viac</a>
            </div>
        </div>
    );
}

export default BlogItem;