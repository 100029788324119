import React from "react";

const ContactMapSection: React.FC = () => {
    return (
        <section className="contact-map-section">
            <h2 className="hidden">Contact map</h2>
            <div className="contact-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2610.9656163604486!2d18.327377476782722!3d49.125288171369554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47148f5468c54257%3A0x50fe3eeadbe7e80b!2sLaw%20firm%20JUDr.%20Jaroslav%20%C4%8Cibenka!5e0!3m2!1sen!2scz!4v1733213367711!5m2!1sen!2scz" width="600" height="450" loading="lazy"></iframe>
            </div>
        </section>
    );
}

export default ContactMapSection;