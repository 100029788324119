import React from "react";
import HeroSlide from "./HeroSlide";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import "./HeroSlider.css";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import bg1 from "../../assets/cibenka/statue-justice-bokeh-background.png";

const HeroSlider: React.FC = () => {
    const btnText = "Čítajte viac";

    return (
        <section className="hero-slider hero-style-2">
            <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                loop={true}
                centeredSlides={true}
                slidesPerView={1}
                spaceBetween={1}
                autoplay={{
                    delay: 8500,
                    disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                navigation={false}
                breakpoints={{
                    0: {
                        slidesPerView: 1
                    },
                    600: {
                        slidesPerView: 1
                    },
                    1000: {
                        slidesPerView: 1
                    }
                }}
                className="swiper-container"
            >
                <SwiperSlide>
                    <HeroSlide
                        background={bg1}
                        dataText="<i class='fi flaticon-dog'></i>Family Law plan<h4>Family Law</h4>"
                        titleText="Advokátska kancelária"
                        slideText="<p>Právne služby poskytované našou advokátskou kanceláriou<br/> profesionálne, rýchlo a odborne.</p>"
                        buttonText={btnText}
                        buttonLink="/advokatska-kancelaria-puchov"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <HeroSlide
                        background={bg1}
                        dataText="<i class='fi flaticon-wounded'></i>Personal injury plan<h4>Personal Injury</h4>"
                        titleText="Právne služby"
                        slideText="<p>Advokátska kancelária sa svojou špecializáciou zameriava najmä na<br/> nehnuteľnosti, obchodné spoločnosti, daňové a rodinné právo.</p>"
                        buttonText={btnText}
                        buttonLink="#nase-zameranie"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <HeroSlide
                        background={bg1}
                        dataText="<i class='fi flaticon-thief'></i>Criminal plan<h4>Criminal Law</h4>"
                        titleText="Nehnuteľnosti"
                        slideText="<p>Advokátska kancelária je špecializovaná na prevody nehnuteľností<br/>na katastri nehnuteľností, kúpne zmluvy, darovacie zmluvy<br/>a iné prevodové zmluvy.</p>"
                        buttonText={btnText}
                        buttonLink="/podla-odvetvia/pravo-nehnutelnosti"
                    />
                </SwiperSlide>
            </Swiper>
        </section>
    );
}

export default HeroSlider;