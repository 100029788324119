import React from "react";

const HeaderSearch:React.FC = () => {
    return (
        <div className="header-search-form-wrapper">
            <button className="search-toggle-btn"><i className="fi flaticon-magnifying-glass"></i></button>
            <div className="header-search-form">
                <form>
                    <div>
                        <input type="text" className="form-control" placeholder="Search here..."></input>
                        <button type="submit"><i className="ti-search"></i></button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default HeaderSearch;