import DbBlog from "../types/DbBlog";
import { BlogEntryItem } from "../types/BlogEntryItem";

const MapBlogFromDbToUi = (dbBlogs: DbBlog[]) => {
    const blogs: BlogEntryItem[] = [];
    dbBlogs.forEach(element => {
        let item = {
            id: (element.id as unknown) as number,
            blog: {
                category: element.category,
                content: element.content,
                description: element.description,
                title: element.title,
                images: [
                    {
                        url: element.image_data,
                        alt: element.image_alt
                    }
                ]
            },
            meta: {
                author: {
                    name: element.author,
                    url:""
                },
                date: element.created_at.substring(0, 10),
                publish: (element.publish as unknown) as boolean,
                url: element.url
            }
        }
        blogs.push(item);
    });

    return blogs;
}

export default MapBlogFromDbToUi;