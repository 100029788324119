import React from "react";

interface PageTitleProps {
    pageName: string;
    desc: string;
    id?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ pageName, desc, id }) => {
    return (
        <section className="page-title" id={id}>
            <div className="container">
                <div className="row">
                    <div className="col col-md-6 col-md-push-3">
                        <h2>{pageName}</h2>
                        <p>{desc}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PageTitle;