import React from "react";
import "./SingleContentSection.css";
import { isLoggedIn } from "../../utils/types/User";

interface SingleContentSectionProps {
    title: string;
    text: string;
    itemUrl?: string;
}

interface EditButtonProps {
    itemUrl?: string;
    id?: number;
}

const SingleContentSection: React.FC<SingleContentSectionProps> = ({ title, text, itemUrl }) => {

    return (
        <section className="service-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-md-8 col-md-push-2">
                        <EditButton itemUrl={itemUrl} />
                        <div className="service-single-content">
                            <h1>{title}</h1>
                            <div dangerouslySetInnerHTML={{ __html: text }}></div>
                            {/* <div className="service-pic">
                                <img src="assets/images/service-single/img-5.jpg" alt="" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const EditButton: React.FC<EditButtonProps> = ({ itemUrl }) => {
    if (!itemUrl) {
        itemUrl = "";
    }
    else {
        itemUrl = `/edit-content/${itemUrl}`
    }
    if (isLoggedIn())
    return (
        <div>
            <a href={itemUrl}><button className="theme-btn">Edit</button></a>
            {/* <a href="/add-content"><button className="theme-btn">Add</button></a> */}
        </div>
    )
    else {
        return <></>
    };
}

export default SingleContentSection;