import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import BlogSingleSection from "../components/BlogPage/SingleBlog/BlogSingleSection";
import NotFound from "../components/Errors/NotFound";
import "./SingleBlogPage.css";
import { BlogThumbnailType } from "../utils/enums/BlogThumbnailType";
import { BlogEntryItem } from "../utils/types/BlogEntryItem";
import BlogButtons from "../components/BlogPage/SingleBlog/BlogButtons";

const SingleBlogPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const blogs = JSON.parse(localStorage.getItem("blogs")!) as BlogEntryItem[];

    useEffect(() => {
        if (blogs && id) {
            const index = blogs.findIndex((blog) => blog.id === parseInt(id));
            if (index !== -1) {
                setCurrentIndex(index);
            }
        }
    }, [id, blogs]);

    const currentItem = blogs[currentIndex];
    const previousItem = blogs[(currentIndex - 1 + blogs.length) % blogs.length];
    const nextItem = blogs[(currentIndex + 1) % blogs.length];

    if (!currentItem) {
        return <NotFound />;
    }

    return (
        <>
            <div>
                <PageTitle pageName="" desc="" id="clanok"/>
                <BlogSingleSection inList={false} entry={currentItem} thumbnailType={BlogThumbnailType.Image} nextId={nextItem.id} prevId={previousItem.id}/>
            </div>
        </>
    );
}

export default SingleBlogPage;
