import React, { useState, useEffect } from 'react';

const CookiesBanner: React.FC = () => {
const [isVisible, setIsVisible] = useState<boolean>(false);

useEffect(() => {
    // Check if the user has already accepted cookies
    const consentGiven = localStorage.getItem('cookieConsent');
    if (!consentGiven) {
    setIsVisible(true);
    }
}, []);

const handleAccept = () => {
    // Store consent in local storage and hide the banner
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
};

const handleDecline = () => {
    // Hide the banner without storing consent
    setIsVisible(false);
};

if (!isVisible) return null;

return (
        <div style={styles.banner}>
            <p style={styles.text}>
                Cookie súbory používame na našej webstránke kvôli správnemu fungovaniu našej stránky, prispôsobeniu analytiky a obsahu návštevníkovi stránky.
            </p>
            <button onClick={handleAccept} style={styles.buttonAccept}>
                Prijať.
            </button>
            <button onClick={handleDecline} style={styles.buttonDecline}>
                Odmietnuť.
            </button>
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
banner: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#333',
    color: '#fff',
    padding: '10px',
    textAlign: 'center',
    zIndex: 1000,
},
text: {
    margin: 0,
    padding: '0 10px',
},
link: {
    color: '#FFD700',
    marginLeft: '5px',
    textDecoration: 'underline',
},
buttonAccept: {
    marginLeft: '15px',
    padding: '5px 10px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
},
buttonDecline: {
    marginLeft: '10px',
    padding: '5px 10px',
    backgroundColor: '#FF6347',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
},
};

export default CookiesBanner;
