import React, { useEffect, useState } from "react";
import FeatureItem from "./FeatureItem";

import taxLawImg from "../../assets/cibenka/civil-law.png";
import famLawImg from "../../assets/cibenka/family-law.png";
import realEstImg from "../../assets/cibenka/real-estate.png";
import tradeLawImg from "../../assets/cibenka/trade-law.png";
import { useCrud } from "../../hooks/CrudHook";
import DbBlog from "../../utils/types/DbBlog";
import MapBlogFromDbToUi from "../../utils/mappers/BlogMapper";
import { BlogEntryItem } from "../../utils/types/BlogEntryItem";

const FeatureSection: React.FC = () => {
    const { data, loading } = useCrud<DbBlog>({ endpoint: "articles" });
    const [filteredBlogs, setFilteredBlogs] = useState<BlogEntryItem[]>([]);

    useEffect(() => {
        if (!loading && data && data.length > 0) {
            const sortedBlogs = MapBlogFromDbToUi(
                data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
            );
            const mainContentBlogs = sortedBlogs.filter(b => b.blog.category === 'mainContent');
            setFilteredBlogs(mainContentBlogs);
        }
    }, [data, loading]);

    if (loading) {
        return <div>Loading ...</div>;
    }

    const findBlogByUrl = (url: string) => {
        return filteredBlogs.find(i => i.meta.url === url) || { blog: { title: "", description: "" } };
    };

    const taxLaw = {
        image: {
            url: taxLawImg,
            alt: "tax-law-image"
        },
        link: {
            url: "/podla-odvetvia/danove-pravo",
            linkName: "Čítajte viac"
        },
        item: findBlogByUrl("danove-pravo"),
    };

    const tradeLaw = {
        image: {
            url: tradeLawImg,
            alt: "trade-law-image"
        },
        link: {
            url: "/podla-odvetvia/obchodne-pravo",
            linkName: "Čítajte viac"
        },
        item: findBlogByUrl("obchodne-pravo"),
    };

    const famLaw = {
        image: {
            url: famLawImg,
            alt: "family-law-image"
        },
        link: {
            url: "/podla-odvetvia/rodinne-pravo",
            linkName: "Čítajte viac"
        },
        item: findBlogByUrl("rodinne-pravo"),
    };

    const realEstate = {
        image: {
            url: realEstImg,
            alt: "real-estate-image"
        },
        link: {
            url: "/podla-odvetvia/pravo-nehnutelnosti",
            linkName: "Čítajte viac"
        },
        item: findBlogByUrl("pravo-nehnutelnosti"),
    };

    return (
        <section className="feature-section section-padding" id="nase-zameranie">
            <div className="col col-lg title-container">
                <h2 className="section-title">Naše zameranie</h2>
            </div>
            <div className="container">
                <div className="row">
                    <FeatureItem header={realEstate.item.blog.title} image={realEstate.image} link={realEstate.link} text={realEstate.item.blog.description} />
                    <FeatureItem header={tradeLaw.item.blog.title} image={tradeLaw.image} link={tradeLaw.link} text={tradeLaw.item.blog.description} />
                    <FeatureItem header={taxLaw.item.blog.title} image={taxLaw.image} link={taxLaw.link} text={taxLaw.item.blog.description} />
                    <FeatureItem header={famLaw.item.blog.title} image={famLaw.image} link={famLaw.link} text={famLaw.item.blog.description} />
                    
                </div>
            </div>
        </section>
    );
};

export default FeatureSection;
