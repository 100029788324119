import React from "react";
import MapIcon from "../../utils/SocialsIconMapper";
import { SocialMediaLink } from "../../utils/types/SocialMediaLink";

import "./TopBar.css"
import { isLoggedIn } from "../../utils/types/User";

interface TopBarProps {
    phoneNumber: string;
    email: string;
    address: string;
    links: SocialMediaLink[];
}

const LogoutButton: React.FC = () => {
    if (isLoggedIn()) {
        return (
            <li><a href="/login"><i className="ti-close"></i></a></li>
        )
    }
    else {
        return <></>
    }
}

const TopBar: React.FC<TopBarProps> = ({ phoneNumber, email, address, links }) => {
    // parse socials list and add icons
    const updatedSocials = links.map((element) => ({
        ...element,
        icon: MapIcon(element.url),
    }));

    return (
        <div className="topbar">
            <div className="container">
                <div className="container">
                    <div className="col col-md-6 contact-info">
                        <div className="row" id="contact-info">
                            <div className="container">
                                <a href={`tel:${phoneNumber}`}><i className="fi flaticon-support"/> {phoneNumber}</a><span>  </span>
                                <a href={`mailto:${email}`}><i className="fi flaticon-email"></i> {email}</a>
                            </div>
                        </div>
                        </div>
                    <div className="col col-md-6">
                        <div className="social">
                            <ul className="clearfix">
                                {updatedSocials.map((item, index) => (
                                <li key={index}>
                                    <a href={item.url}><i className={item.icon}></i></a>
                                </li>
                            ))}
                            <LogoutButton />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopBar;