import React from "react";
import SocialNewsletter from "./SocialNewsletter";
import UpperFooter from "./UpperFooter";
import LowerFooter from "./LowerFooter";
import "./Footer.css"

const Footer: React.FC = () => {
    return (
        <footer className="site-footer">
            <UpperFooter />
        </footer>
    );
}

export default Footer;