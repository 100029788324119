import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import HomePage from './Pages/HomePage';
import NotFound from './components/Errors/NotFound';

import BlogPage from './Pages/BlogPage';
import TradeLawPage from './Pages/TradeLawPage';
import FamilyLawPage from './Pages/FamilyLawPage';
import RealEstatePage from './Pages/RealEstatePage';

import Imports from './utils/Imports';
import ContactPage from './Pages/ContactPage';
import SingleBlogPage from './Pages/SingleBlogPage';
import AddBlogPage from './Pages/AddBlogPage';
import LoginPage from './Pages/LoginPage';
import EditBlogPage from './Pages/EditBlogPage';
import AboutPage from './Pages/AboutPage';
import CreateCompanyPage from './Pages/CreateCompanyPage';
import WorkLawPage from './Pages/WorkLawPage';
import Unauthorized from './components/Errors/Unauthorized';
import EditContentPage from './Pages/EditContentPage';
import CreateTradeRegistryPage from './Pages/TradeRegistryPage';
import TaxLawPage from './Pages/TaxLawPage';
import CreateTaxControlPage from './Pages/TaxControlPage';
import CreateFinesPage from './Pages/FinesPage';
import GdprPage from './Pages/GdprPage';
import SubjectRegistryPage from './Pages/SubjectRegistryPage';
import PartnerRegistryPage from './Pages/PartnerRegistryPage';
import CookiesBanner from './components/CookiesBanner';

function App() {
  return (
    <Router>
      <Imports />
      <div className="page-wrapper">
        <CookiesBanner />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/index" element={<HomePage />} />
          <Route path="*" element={<NotFound />}/>
          <Route path="/unauthorized" element={<Unauthorized />}/>
          <Route path="/advokatska-kancelaria-puchov" element={<AboutPage />} />
          <Route path="/sluzby/zalozenie-a-zrusenie-spolocnosti" element={<CreateCompanyPage />} />
          <Route path="/sluzby/zmeny-v-obchodnych-registroch" element={<CreateTradeRegistryPage />} />
          <Route path="/sluzby/vymahanie-pohladavok" element={<CreateFinesPage />} />
          <Route path="/sluzby/danove-kontroly" element={<CreateTaxControlPage />} />
          <Route path="/sluzby/register-partnerov-verejneho-sektora" element={<PartnerRegistryPage /> }/>
          <Route path="/sluzby/register-hospodarskych-subjektov" element={<SubjectRegistryPage />} />
          <Route path="/sluzby/gdpr" element={<GdprPage />} />
          <Route path="/podla-odvetvia/pracovne-pravo" element={<WorkLawPage />} />
          <Route path="/aktuality" element={<BlogPage />}/>
          <Route path="/podla-odvetvia/danove-pravo" element={<TaxLawPage />}/>
          <Route path="/podla-odvetvia/obchodne-pravo" element={<TradeLawPage />}/>
          <Route path="/podla-odvetvia/rodinne-pravo" element={<FamilyLawPage />}/>
          <Route path="/podla-odvetvia/pravo-nehnutelnosti" element={<RealEstatePage />}/>
          <Route path="/kontakt" element={<ContactPage />}/>
          <Route path="/aktuality/:id/:title" element={<SingleBlogPage />}/>
          <Route path="/add-blog" element={<AddBlogPage />}/>
          <Route path="/edit-blog/:id/:title" element={<EditBlogPage />}/>
          <Route path="/edit-content/:title" element={<EditContentPage />}/>
          <Route path="/add-content" element={<EditContentPage />}/>
          <Route path="/login" element={<LoginPage />}/>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
