import React from "react";
import WidgetText from "../Widgets/WidgetText";
import WidgetList from "../Widgets/WidgetList";
import WidgetLink from "../Widgets/WidgetLink";
import ContactMapSection from "../ContactSection/ContactMapSection";

const UpperFooter: React.FC = () => {
    type Item = [string, string | null];

    interface Link {
        url: string,
        linkName: string
    }

    const textLines: string[] = [
        "Advokátska kancelária JUDr. Jaroslav Čibenka s.r.o.",
        "Námestie Slobody 1901/66,",
        "020 01 Púchov",
        "+421 940 50 50 51",
        "office@cibenka.sk"
    ]

    const leftLinks: Link[] = [
        {
            linkName: "Právo nehnuteľností",
            url: "/podla-odvetvia/pravo-nehnutelnosti"
        },
        {
            linkName: "Obchodné právo",
            url: "/podla-odvetvia/obchodne-pravo"
        },
        {
            url: "/podla-odvetvia/danove-pravo",
            linkName: "Daňové právo"
        },
        {
            linkName: "Rodinné právo",
            url: "/podla-odvetvia/rodinne-pravo"
        },
    ]

    return (
        <div className="upper-footer">
            <div className="container">
                <div className="row">
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <div className="widget about-widget">
                        <div className="widget-title">
                            <h3>Kontakt</h3>
                        </div>
                            <p>Advokátska kancelária<br></br>JUDr. Jaroslav Čibenka s.r.o.</p>
                            <p>Námestie Slobody 1901/66<br></br>020 01 Púchov</p>
                            <p style={{textDecoration: "underline"}}><a href="tel:+421940505051">+421 940 50 50 51</a></p>
                            <p style={{textDecoration: "underline"}}><a href="mailto:office@cibenka.sk">office@cibenka.sk</a></p>
                    </div>
                </div>
                    <WidgetLink title="Rýchle odkazy" linksLeft={leftLinks} linksRight={[]}/>
                    <div className="col col-lg-6 col-md-6 col-sm-6">
                        <ContactMapSection />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpperFooter;