import { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';

interface CrudConfig<T> {
    endpoint: string;
    initialData?: T[];
}

interface CrudHook<T> {
    data: T[];
    loading: boolean;
    error: string | null;
    create: (item: T) => Promise<void>;
    read: () => Promise<void>;
    update: (id: number, item: T) => Promise<void>;
    remove: (id: number) => Promise<void>;
    uploadImage: (image: File) => Promise<string>;
}

export function useCrud<T extends { id: any }>({ endpoint, initialData = [] }: CrudConfig<T>): CrudHook<T> {
    const [data, setData] = useState<T[]>(initialData);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const url = `https://visionwebmedia.cz/${endpoint}`;
    const imgUrl = "https://visionwebmedia.cz/image_objs";
    const webOrigin = "cibenka.sk";

    useEffect(() => {
        read();
    }, []);

    const create = async (item: T) => {
        const headers = {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("token"),
            "odkial-ide": webOrigin,
        }
        setLoading(true);
        setError(null);
        try {
            const response: AxiosResponse<T> = await axios.post(url, item, { headers: headers});
            setData(prevData => [...prevData, response.data]);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
        } finally {
            console.log("created");
            setLoading(false);
        }
    };

    const read = async () => {
        const headers = {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("token"),
            "odkial-ide": webOrigin,
        }
        setLoading(true);
        setError(null);
        try {
            const response: AxiosResponse<T[]> = await axios.get(url, {headers: headers});
            setData(response.data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
        } finally {
            setLoading(false);
        }
    };

    const update = async (id: number, item: T) => {
        const headers = {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("token"),
            "odkial-ide": webOrigin,
        }
        setLoading(true);
        setError(null);
        try {
            const response: AxiosResponse<T> = await axios.put(`${url}/${id}`, item, {headers: headers});
            setData(prevData => prevData.map(dataItem => (dataItem.id === (id as unknown) ? response.data : dataItem)));
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
        } finally {
            setLoading(false);
        }
    };

    const remove = async (id: number) => {
        const headers = {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("token"),
            "odkial-ide": webOrigin,
        }
        setLoading(true);
        setError(null);
        try {
            await axios.delete(`${url}/${id}`, {headers: headers});
            setData(prevData => prevData.filter(dataItem => dataItem.id !== (id as unknown)));
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Unknown error');
        } finally {
            setLoading(false);
        }
    };

    

    const uploadImage = async (file: File): Promise<string> => {
        const headers = {
            'Authorization': localStorage.getItem('token')!,
            'Content-Type': 'multipart/form-data',
            "odkial-ide": webOrigin,
        }
        const formData = new FormData();
        formData.append('image_obj[image]', file);

        try {
            const response: AxiosResponse<{ id: number; image_alt: string | null; imageUrl: string }> = await axios.post(imgUrl, formData, {
                headers: headers
            });
            return response.data.imageUrl;
        } catch (error) {
            console.error('Error uploading image:', error);
            return '';
        }
    };

    return { data, loading, error, create, read, update, uploadImage, remove };
}